import { SecurityRole } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  PltUser,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";

import { Options } from "./PltUserAuthenticationForm.types";
import { UserFormValues } from "./UserForm.types";

export const getOptionValue = (option: Options) => {
  if (option === Options.on) return true;
  if (option === Options.off) return false;
  if (option === Options.default) return undefined;
};

export const getUserAuthDtoPayload = (options: {
  userId: string;
  tenantId: string;
  values: UserFormValues;
}): UserDefaultAuthenticationDto => {
  const { userId, values, tenantId } = options;
  return {
    userId,
    tenantId,
    deviceMfaMaxDuration: values.deviceMfaMaxDuration
      ? Number(values.deviceMfaMaxDuration)
      : undefined,
    sessionMaxDuration: values.sessionMaxDuration
      ? Number(values.sessionMaxDuration)
      : undefined,
    quickPinRequired: getOptionValue(values.quickPinRequired),
    longPasswordRequired: getOptionValue(values.longPasswordRequired),
    requireAlternateEmailAuthenticationMethod: getOptionValue(
      values.requireAlternateEmailAuthenticationMethod
    ),
    requireAlternateEmailAuthenticationStrength: getOptionValue(
      values.requireAlternateEmailAuthenticationStrength
    ),
    requirePhoneAuthenticationMethod: getOptionValue(
      values.requirePhoneAuthenticationMethod
    ),
    requirePhoneAuthenticationStrength: getOptionValue(
      values.requirePhoneAuthenticationStrength
    ),
    requireTotpAuthenticationMethod: getOptionValue(
      values.requireTotpAuthenticationMethod
    ),
    requireTotpAuthenticationStrength: getOptionValue(
      values.requireTotpAuthenticationStrength
    ),
    ipRanges: values?.ipRanges
  };
};

export const getUserDtoPayload = (options: {
  user: PltUser;
  values: UserFormValues;
  securityRoles: SecurityRole[];
}): PltUser => {
  const { user, securityRoles, values } = options;
  return {
    ...user,
    firstName: values.firstName,
    lastName: values.lastName,
    status: values.status,
    isInactive: values.isInactive,
    businessRoles: values.businessRoles ?? [],
    roles: [],
    securityRoles: values.securityRoles.map(code => {
      return {
        code,
        securityRoleId: securityRoles.find(role => role.code === code)?.id
      };
    })
  };
};

export const hasDirtyFieldsKeys = (dirtyFields: object, payload: object) =>
  Object.keys(dirtyFields).some(f => Object.keys(payload).includes(f));
